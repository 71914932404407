export const environment = {
  production: false,
  authority: "https://login.microsoftonline.com/6e57fc1a-413e-4050-91da-7d2dc8543e3c/",
  baseUrl: 'https://taxuserverse-stg.bdo.com/api/',
  redirectUrl: 'https://taxuserverse-stg.bdo.com/',
  clientId: '24aa7347-e6fe-41df-a555-d41e9e7441e5',
  tenantId: '6e57fc1a-413e-4050-91da-7d2dc8543e3c',
  apiClientId: '031a00fc-ac03-4b9a-923e-3375aea58e8f',
  TIMEOUT_DURATION_MS: 2700000
};
